import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SEO from "../../components/SEO/SEO";
import Documento from "../../components/Documento/Documento";

import imgQuemSomos from "../../assets/images/a-escola/quem-somos/quemSomos.png";

import '../../components/css/images.css'

export default function QuemSomos() {
	return (
		<Layout>
			<SEO title="Propósito" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="order-1 order-sm-0 my-auto px-4">
						<h1 className="pageTitle fgprimary-gradient pb-3 d-none d-md-block">
							quem somos
						</h1>
						<h1
							className="pageTitle fgprimary-gradient pb-3 d-block d-md-none"
							style={{ fontSize: "3.3em" }}
						>
							quem somos
						</h1>
						<p className="h3 fgprimary-gradient">// propósito</p>
						<p className="text-justify">
							A Escola de Aplicação Feevale faz a diferença. Porque aprender é
							completar a incompletude. Aprender é ver. Diferente de olhar.
						</p>
						<p className="text-justify">
							Em um ambiente acolhedor, com espaços de fala e de escuta,
							permeados pelas dimensões do “ser”, compartilhamos toda a
							estrutura física e os recursos humanos e tecnológicos da
							Universidade Feevale, articulando propostas de aprendizagens
							inovadoras, em busca da autogestão e do desenvolvimento de um
							projeto de vida.
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgQuemSomos} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={4} className="pb-3 pt-3 pt-md-0">
						<div className="bgprimary-gradient p-4 text-white text-center h-100 py-auto">
							<h3>por que fazemos?</h3>
							<p className="pt-2">
								Acreditamos na construção de aprendizagens efetivas e afetivas
								que valorizem o potencial e a trajetória de cada estudante.
							</p>
						</div>
					</MDBCol>
					<MDBCol col={12} md={4} className="pb-3">
						<div className="bgprimary-gradient p-3 text-white text-center h-100 py-auto">
							<h3>como fazemos?</h3>
							<p className="pt-2">
								Promovemos vivências que desenvolvem inteligências múltiplas em
								projetos incríveis.
							</p>
						</div>
					</MDBCol>
					<MDBCol col={12} md={4} className="pb-3">
						<div className="bgprimary-gradient p-3 text-white text-center h-100 py-auto">
							<h3>o que entregamos?</h3>
							<p className="pt-2">
								Desenvolvemos a autogestão, a criatividade para a inovação e aos
								projetos de vida.
							</p>
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgprimary-gradient pt-4">
							ecossistemas de aprendizagem
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p className="text-justify">
							// norteados pelas habilidades e competências estabelecidas pela
							Base Nacional Comum Curricular e pelo Referencial Curricular
							Gaúcho, promovemos a aprendizagem inovadora organizadas por
							ciclos, inspirada nas metodologias e tecnologias contemporâneas,
							de maneira transdisciplinar, a partir da observação de fenômenos
							locais, regionais e globais, desenvolvendo projetos através da
							resolução de problemas. A customização da aprendizagem do
							estudante, a mentoria, os projetos transdisciplinares, workshops,
							investigação científica, desafio, festivais e internacionalização
							integram a metodologia da Escola de Aplicação Feevale.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgprimary-gradient pt-4">nossa história</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p className="text-justify">
							// a Escola de Aplicação Feevale, vinculada à Pró-Reitoria de
							Ensino da Universidade Feevale, fica situada em Novo Hamburgo, no
							Rio Grande do Sul e é mantida pela Associação Pró-Ensino Superior
							em Novo Hamburgo – ASPEUR, que foi fundada em 1969, uma entidade
							comunitária, sem fins lucrativos, constituída por representantes
							da comunidade regional que, com esforço solidário e comprometido,
							vem administrando a Instituição há 50 anos.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						1989
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						No dia 27 de fevereiro a Escola de 2º Grau Feevale teve seu
						funcionamento homologado. E março, a Feevale implantou a Escola de
						2º Grau, que também oferecia dois cursos técnicos: Desenhista de
						Calçados e Contabilidade.
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						1998
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						De um desejo da comunidade em construir uma escola com uma proposta
						pedagógica inovadora em Novo Hamburgo, nasceu a Escola de 1º Grau.
						Entre outras mudanças, propôs a unificação das escolas de 1º e 2º
						graus, consequentemente, a denominação mudou para Centro de Ensino
						Médio. Além disso, o novo Regimento propôs que a escola pudesse
						organizar-se a partir de ciclos de aprendizagem, em substituição ao
						modelo seriado até então utilizado.
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						1999
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						Já com a implantação dos ciclos de aprendizagem, o Centro de Ensino
						Médio Feevale passou a discutir questões relacionadas aos novos
						desafios da educação escolar contemporânea, como a
						internacionalização e a educação inclusiva, cuja prática é
						considerada referência na região do Vale do Rio dos Sinos.
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						2003
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						Na perspectiva de indissociabilidade entre os diferentes níveis de
						aprendizagem propostos pelo Centro Universitário Feevale e do
						constante aprimoramento da relação entre a Educação Básica e as
						atualizações pedagógicas dos professores, a Escola passou a ser
						denominada Escola de Educação Básica Feevale–Escola de Aplicação.
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						2007
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						Atendendo à Lei Federal nº 11.274, de 06 de fevereiro de 2006, que
						determina a duração de nove anos para o Ensino Fundamental, a Escola
						alterou sua organização curricular nesse nível de aprendizagem. Da
						mesma forma, neste período, reformulou os currículos dos Cursos
						Técnicos oferecidos.
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						2011
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						A Escola passou a ter Educação Infantil no turno da tarde, avançando
						a cada ano com a etapa e o ciclo subsequente, até o 2º ciclo do
						Ensino Fundamental. Paralelo a isso, passou a oferecer o turno
						integral que, à tarde, recebeu o nome de Currículo Ampliado, com a
						oferta de oficinas de caráter lúdico, social, afetivo, ambiental e
						expressivo, voltadas para o desenvolvimento contínuo.
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						2020
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						A Escola iniciou a implantação dos Itinerários Formativos no Ensino
						Médio.
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						2021
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						A Escola readequou o currículo dos Cursos Técnicos em Publicidade e
						Informática para a Internet e abriu quatro cursos novos, sendo eles:
						Técnico em Administração, Técnico em Desenvolvimento de Aplicativos,
						Técnico com Gestão de Negócios Inovadores e Técnico em Multimídia.
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol
						col={12}
						md={2}
						className="h1 text-center my-auto fgprimary-gradient font-weight-bold py-4 py-md-0"
					>
						2022
					</MDBCol>
					<MDBCol col={12} md={10} className="py-1 text-justify">
						Ampliando a oferta de atendimento, a Escola abriu turmas de Educação
						Infantil para 2 e 3 anos de idade.
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<p className="text-justify pt-3">
							// a Escola constitui-se em um espaço privilegiado e se apresenta
							como possibilidade de integração e construção de novos saberes na
							área educacional, relacionada com os cursos de graduação e
							pós-graduação da Universidade Feevale. Assim, o desafio da
							interface com as disciplinas e atividades de pesquisa da
							Universidade é um dos aspectos que leva a constantes reflexões,
							pois, como Escola de Aplicação tem o compromisso de promover
							experiências que consolidem a indissociabilidade entre
							aprendizagem e pesquisa, entre teoria e prática.
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<Documento
						name="Projeto Político-Pedagógico"
						file="https://www.feevale.br/s/conteudo/31c704e9-fedb-4c3f-9b20-ae2642b186ac/Projeto_Politico-Pedagogico_Escola_de_Aplicacao_Feevale_2022.pdf"
						extension="pdf"
						bgColor="fgprimary-gradient"
					/>
					<Documento
						name="Regimento Parcial da Educação Infantil"
						file="https://www.feevale.br/s/conteudo/23ff525a-da07-45b8-b7f9-30b578e99c63/Regimento_Parcial_da_Educa%c3%a7%c3%a3o_Infantil_-_2021.pdf"
						extension="pdf"
						bgColor="fgprimary-gradient"
					/>
				</MDBRow>
				<MDBRow>
					<Documento
						name="Regimento Escolar Parcial Ensino Fundamental e Ensino Médio"
						file="https://www.feevale.br/s/conteudo/64db600c-cc26-4987-ab3b-a1443af94765/Regimento_Parcial_Ensino_Fundamental_e_Ensino_Medio_FEEVALE.pdf"
						extension="pdf"
						bgColor="fgprimary-gradient"
					/>
					<Documento
						name="Regimento Parcial da Educação Profissional Técnica de Nível Médio"
						file="https://www.feevale.br/s/conteudo/09d7fefc-ebde-46f5-af6b-ce65b17081a9/Regimento_Parcial_do_Ensino_T%c3%a9cnico_-_2021.pdf"
						extension="pdf"
						bgColor="fgprimary-gradient"
					/>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
